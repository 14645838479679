import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WListOfList } from '../../elements/tokens/WListOfList';
import { WListOfListOfString } from '../../elements/tokens/WListOfListOfString';

/**
 * Convert to a list.
 * Can be a list (reals), a list of list (reals) or a list of list of strings
 */
export class Listify extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {

    if (args.length === 0) {
      return args.env.culture.createEmptyList();
    }

    if (args.length >= 2) {
      let listOfListOfReals = [];
      let listOfListOfString = [];
      let isValidListOfListOfReal = true;
      let isValidListOfListOfString = true;
      for (let i = 0; i < args.length; i += 1) {
        if (isValidListOfListOfReal) {
          const reals = args.getReals(i);
          if (reals) {
            listOfListOfReals.push(reals);
          } else {
            isValidListOfListOfReal = false;
          }
        }
        if (isValidListOfListOfString) {
          const strings = args.getStrings(i);
          if (strings) {
            listOfListOfString.push(strings);
          } else {
            isValidListOfListOfString = false;
          }
        }
        if (!isValidListOfListOfReal && !isValidListOfListOfString) {
          return null;
        }
      }

      if (isValidListOfListOfReal) {
        return new WListOfList(listOfListOfReals, args.env.culture.listFormatter);
      }

      if (isValidListOfListOfString) {
        return new WListOfListOfString(listOfListOfString, args.env.culture.listFormatter);
      }
    }

    const list = args.getList(0);
    if (list) {
      return list;
    }

    const finiteSet = args.getFiniteSet(0);
    if (finiteSet) {
      if (finiteSet.isNumeric) {
        return finiteSet.toList();
      }

      if (finiteSet.isStrings) {
        return finiteSet.toListOfString();
      }

      if (finiteSet.hasNumericElements) {
        return finiteSet.toListOfList();
      }

      if (finiteSet.hasStringElements) {
        return finiteSet.toListOfListOfString();
      }
    }

    const range = args.getRange(0);
    if (range) {
      return args.env.culture.listFactory.createList(range.toItems());
    }

    const matrix = args.getMatrix(0);
    if (matrix) {
      return WList.createFromReals(matrix.values, args.env.culture.listFormatter);
    }

    return null;
  }
}
