import { api } from '../../utils/fetch';

// PublisherAPI: POST /data, same body
export const setData = async <T>(domain: string, key: string, data: T): Promise<boolean> => {
  const bodyFormData = new FormData();
  bodyFormData.set('domain', domain);
  bodyFormData.set('key', key);
  bodyFormData.set('data', JSON.stringify(data));
  const response = await api('/Publisher/Workbench/SetDataJson', {
    method: 'POST',
    body: bodyFormData,
  });
  return response.ok;
};
