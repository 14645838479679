import { allVariantsData } from '../devServerFallbacks';
import { IVariantMetadata } from '../../models/IVariantMetadata';
import { api } from '../../utils/fetch';

export interface IDataItem<TData extends {}> {
  Domain: string;
  Key: string;
  Data: TData;
}

export interface IGetDataResult<TData extends {}> {
  Domain: string;
  Key: string;
  Items: ReadonlyArray<IDataItem<TData>>;
}

// PublisherAPI: GET /data, domain and key in queryString
export async function getData<TData extends {}>(domain: string, key?: string): Promise<IGetDataResult<TData>> {
  const search = new URLSearchParams({ domain, outputFormat: 'json', ...(key ? { key } : {}) });
  const response = await api<IGetDataResult<string>>(`/Publisher/Workbench/GetData?${search}`);
  const json = await response.json();
  return { ...json, Items: json.Items.map(item => ({ ...item, Data: JSON.parse(item.Data) })) };
}

export function getLocalVariantsData(): IGetDataResult<IVariantMetadata> {
  const json: IGetDataResult<string> = allVariantsData;
  return { ...json, Items: json.Items.map(item => ({ ...item, Data: JSON.parse(item.Data) })) };
}
