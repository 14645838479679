import { Point } from '../../js/geom/Point';
import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { RealElement } from '../elements/abstract/RealElement';
import { TokenElement } from '../elements/abstract/TokenElement';
import { IntervalsFactory } from '../elements/factories/IntervalsFactory';
import { ListFactory } from '../elements/factories/ListFactory';
import { DefaultFormats } from '../elements/formats/DefaultFormats';
import { IFormats } from '../elements/formats/IFormats';
import { ListFormatter } from '../elements/formats/lists/ListFormatter';
import { LocaleNumberFormatter } from '../elements/formats/numbers/LocaleNumberFormatter';
import { WFiniteSet } from '../elements/tokens/WFiniteSet';
import { WList } from '../elements/tokens/WList';
import { WMatrix } from '../elements/tokens/WMatrix';
import { WNotANumber } from '../elements/tokens/WNotANumber';
import { WNumber } from '../elements/tokens/WNumber';
import { WPoint } from '../elements/tokens/WPoint';
import { WRadical } from '../elements/tokens/WRadical';
import { WRange } from '../elements/tokens/WRange';
import { WRational } from '../elements/tokens/WRational';
import { WVariable } from '../elements/tokens/WVariable';
import { Feminize } from '../elements/utils/Feminize';
import { Resources } from '../localization/Resources';
import { Vocabulary } from '../localization/Vocabulary';
import { IVocabulary } from '../localization/IVocabulary';
import { CurrencyInfo } from '../localization/CurrencyInfo';
import { WNumberSet } from '../elements/tokens/WNumberSet';
import { PercentFormatter } from '../elements/formats/numbers/PercentFormatter';
import { ILocaleConfiguration } from '../localization/ILocaleConfiguration';
import { IDictionary } from '../../js/utils/IDictionary';
import fr_motsGenres from '../../locale/fr_motsGenres.json';

/**
 *
 */
export class CultureInfo {

  /**
   *
   */
  constructor(configuration: ILocaleConfiguration) {
    this._configuration = configuration;
  }

  /**
   *
   * @private
   */
  private _configuration: ILocaleConfiguration;

  public get configuration(): ILocaleConfiguration {
    return this._configuration;
  }

  /**
   *
   */
  public get languageCode(): string {
    return this.configuration.localeName.substring(0, 2);
  }

  /**
   * Returns a localized string resource.
   *
   * @param key
   * @param args
   */
  public getString(key: string, ...parameters:any[]): string {
    const bundleName = key.substring(0, key.indexOf('.'));
    const resourceName = key.substring(key.indexOf('.') + 1);
    return Resources.getString(bundleName, resourceName, parameters, this.configuration.localeName);
  }

  /**
   *
   * @param key
   */
  public getStringArray(key: string): ReadonlyArray<string> {
    const bundleName = key.substring(0, key.indexOf('.'));
    const resourceName = key.substring(key.indexOf('.') + 1);
    return Resources.getStringArray(bundleName, resourceName, this.configuration.localeName);
  }

  /**
   * Returns the list of default formats for this culture.
   */
  private _formats:IFormats;

  public get formats():IFormats{
    if(!this._formats){
      this._formats = new DefaultFormats(this);
    }
    return this._formats;
  }

  /**
   *
   */
  public get numberFormatter():LocaleNumberFormatter{
    return <LocaleNumberFormatter>this.formats.numberFormatImpl ;
  }

  /**
   *
   */
  public get listFormatter():ListFormatter{
    return <ListFormatter>this.formats.listFormatImpl ;
  }

  /**
   *
   */
  private _listFactory:ListFactory;

  public get listFactory():ListFactory{
    if(!this._listFactory){
      this._listFactory = new ListFactory(this);
    }
    return this._listFactory;
  }

  /**
   *
   */
  private _intervalsFactory:IntervalsFactory;

  public get intervalsFactory():IntervalsFactory{
    if(!this._intervalsFactory){
      this._intervalsFactory = new IntervalsFactory(this);
    }
    return this._intervalsFactory;
  }

  /**
   *
   */
  public formatNumber(value:number,
                      format: 'percent' | null = null,
                      minDecPlaces: number = 0,
                      maxDecPlaces = Number.MAX_SAFE_INTEGER,
                      thousandSeparatorThreshold: number = Number.NaN):string{
    if(format){
      switch(format){
        case 'percent':
          return new PercentFormatter(this).toLocaleString(value);
      }
    }

    const useDefaultFormatter =
      minDecPlaces === 0 &&
      maxDecPlaces === Number.MAX_SAFE_INTEGER &&
      isNaN(thousandSeparatorThreshold);

    const formatter =
      useDefaultFormatter
        ? this.formats.numberFormatImpl
        : new LocaleNumberFormatter(this, minDecPlaces, maxDecPlaces, false, thousandSeparatorThreshold);

    return formatter.toLocaleString(value);
  }

  /**
   * Returns null if value is one of the following cases:
   * 	- Number.NaN
   *  - Number.POSITIVE_INFINITY
   *  - Number.NEGATIVE_INFINITY
   */
  public createNumber(value:number):WNumber{
    if(isNaN(value)){
      return null;
    }
    if(value === Number.POSITIVE_INFINITY){
      return null;
    }
    if(value === Number.NEGATIVE_INFINITY){
      return null;
    }
    const n:WNumber = new WNumber(value, 1, false, this.formats.numberFormatImpl);
    n.userData = String(value);
    return n;
  }

  /**
   *
   * @param _set
   * @returns {any}
   */
  public createNumbers(_set: string): WNumberSet {
    switch (_set) {
      case 'ℝ':
      case 'ℕ':
      case 'ℙ':
      case 'ℚ':
      case 'ℤ':
      case 'ℂ':
        return new WNumberSet(_set, this.formats.intervalFormatImpl);
      default:
        return null;
    }
  }

  /**
   *
   */
  public parseNumber(value:number):TokenElement{
    const n = this.createNumber(value);
    return n || WNotANumber.getInstance();
  }

  /**
   *
   */
  public parseCoor(x:number, y:number):WPoint{
    return new WPoint(
      this.createNumber(x),
      this.createNumber(y),
      this.formats.pointFormatImpl);
  }

  /**
   *
   */
  public parsePoint(pt:Point):WPoint{
    return this.parseCoor(pt.x, pt.y);
  }

  /**
   *
   */
  public createRational(num:number, den:number):WRational{
    return new WRational(num, den, this.formats.rationalFormatImpl);
  }

  /**
   *
   */
  public createVariable(symbol:string):WVariable{
    const v:WVariable = new WVariable(symbol, this.numberFormatter);
    v.userData = symbol;
    return v;
  }

  /**
   *
   */
  public createEmptyList():WList{
    const list:WList = new WList([], this.formats.listNFormatImpl);
    list.userData = '()';
    return list;
  }

  /**
   *
   */
  public createEmptySet():WFiniteSet{
    const _set:WFiniteSet =
      new WFiniteSet(
        [],
        this.formats.setFormatImpl);
    _set.userData = '{}';
    return _set;
  }

  /**
   *
   */
  public createMatrix(values:number[], columns:number):WMatrix{
    const o:RealElement[] = [];
    for(let i:number = 0 ; i < values.length ; i++){
      o.push(this.createNumber(values[i]));
    }
    return new WMatrix(o, columns, this.formats.matrixFormatImpl);
  }

  /**
   * Returns a 2-columns matrix.
   */
  public createPointsMatrix(values:Point[]):WMatrix{
    const o:RealElement[] = [];
    for(let i:number = 0 ; i < values.length ; i++){
      o.push(this.createNumber(values[i].x), this.createNumber(values[i].y));
    }
    return new WMatrix(o, 2, this.formats.matrixFormatImpl);
  }

  /**
   *
   */
  public createSqrt(base:number):WRadical{
    return new WRadical(
      this.createNumber(base),
      this.createNumber(2),
      this.createNumber(1),
      this.formats.radicalFormatImpl);
  }

  /**
   * If a range is created using the interval [1, 10] with step 4,
   * the elements are {1, 5, 9} which doesn't include 10. This range can
   * then be normalized with the values [1, 9] with step 4.
   */
  public createNormalizedRange(minimum:number, maximum:number, step:number):ContentElement{
    if(minimum === maximum){
      return this.createNumber(minimum);
    }

    const range:WRange = new WRange(minimum, maximum, step, this.numberFormatter);

    const actualMaximum:number = range.valueAt(range.getCardinal() - 1);

    if(actualMaximum !== maximum){
      return new WRange(minimum, actualMaximum, step, this.numberFormatter);
    }

    return range;
  }

  /**
   *
   */
  public get longDivisionImplementation(): 'euclidienne' | 'english' | string {
    switch(this.configuration.longDivisionStyle){
      case 'shortStackedRightRight':
        return 'euclidienne';
      case 'leftTop':
        return 'english';
    }
    throw new Error();
  }

  /**
   *
   */
  public get feminize():Feminize{
    return new Feminize(this);
  }

  /**
   *
   */
  public get currency():CurrencyInfo{
    return CurrencyInfo.parse(this.configuration.currencyName);
  }

  /**
   *
   */
  public get vocabulary():IVocabulary{
    return Vocabulary.getVocabulary(this.configuration.localeName);
  }

  /**
   *
   */
  public get motsGenres(): IDictionary {
    if(this.languageCode === 'fr'){
      return fr_motsGenres;
    }
    return null;
  }

  /**
   *
   */
  public isPlural(quantity:number):boolean{
    switch(this.configuration.pluralizeWhen){
      case 'absoluteQuantityGreaterOrEqualTo2':
        return Math.abs(quantity) >= 2;
      case 'quantityNotEqualTo1':
        return quantity !== 1;
      default:
        throw new Error();
    }
  }

  /**
   * Apply pluralization rules on a string based on a quantity.
   */
  public pluralize(
      value:string,
      quantity:number):string{
    return XString.pluralize(value, this.isPlural(quantity));
  }

  /**
   *
   */
  public revertNumberLocalization(valueArg:string):string{
    let value = valueArg;
    if(this.numberFormatter.decimalSeparator === ','){
      value = value.replace(/(\d)\,(\d)/, '$1.$2');
    }
    value = value.split(this.numberFormatter.thousandSeparator).join('');
    return value;
  }

  /**
   *
   */
  public formatTitle(value:string):string{
    return this.configuration.titleCaps ?
      XString.titleCaps(value) :
      value;
  }

  /**
   *
   * @param localeName
   */
  public localeMatchQuality(localeName: string): number {
    if (!localeName) {
      return 0;
    }
    if(this.configuration.localeName === localeName){
      return 2;
    }
    if(this.configuration.localeName.substring(0, 2) === localeName.substring(0, 2)){
      return 1;
    }
    return 0;
  }

  public get acceptIntervalNotationWithParenthesis(): boolean {
    return this.configuration.acceptIntervalsNotations.includes('bracketsAndParentheses') ||
      this.configuration.acceptIntervalsNotations.includes('bracketsAndParenthesesExceptInfinity');
  }

}
