import { IFeatureFlags } from '../models/IFeatureFlags';
import { platformApi, publisherApi } from '../utils/fetch';

interface IApiFeatureFlags {
  projects: Record<string, IFeatureFlags>;
}

// PublisherAPI: GET /features
export const getFeatureFlags = async (): Promise<IFeatureFlags> => {
  const response = await publisherApi<IFeatureFlags>('/features');
  return response.json();
};

export const getWorkbookFeatureFlags = async (): Promise<IFeatureFlags> => {
  const response = await platformApi<IApiFeatureFlags>('/features');
  const data = await response.json();
  return data.projects.Workbook ?? null;
};
