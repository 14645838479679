import { IPageContent } from '@scolab/content-model';
import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';

export interface IPageContentOverwriteDraftResponse {
  // When bumpVersion is set to true, returns the new head version.
  headVersion?: number;
}

// PublisherAPI: PUT /pages/content/overwrite-draft, remove clientApplication.params, add editionWindowId, same body
export const pageContentOverwriteDraft = async (pageContent: IPageContent,
                                                bumpVersion: boolean,
                                                clientApplication: ClientApplicationModel): Promise<IPageContentOverwriteDraftResponse> => {

  const data = new FormData();
  data.append('pageContent', JSON.stringify(pageContent));
  data.append('bumpVersion', String(bumpVersion));

  const response = await api<IPageContentOverwriteDraftResponse>(`/Publisher/PageContent/OverwriteDraft?${clientApplication.params}`, {
    method: 'POST',
    body: data,
  });
  return response.json();
};
