import { IPageContent } from '@scolab/content-model';
import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';

export interface IPageContentGetPageContentWithoutLockResponse {
  pageContent: IPageContent;
  pageVersion: {
    version: number | null,
  };
}

// PublisherAPI: GET /pages/{pageGuid}/content/no-lock, remove clientApplication.params, add editionWindowId
export const pageContentGetPageContentWithoutLockLatestCopy = async (pageGuid: string,
                                                                     clientApplication: ClientApplicationModel): Promise<IPageContentGetPageContentWithoutLockResponse> => {
  const location = '/Publisher/PageContent/GetPageContentByVersion';
  const response = await api<IPageContentGetPageContentWithoutLockResponse>(`${location}?pageGuid=${pageGuid}&${clientApplication.params}`);
  const rawResponse = await response.json();
  return {
    pageVersion: rawResponse.pageVersion,
    pageContent: JSON.parse(`${rawResponse.pageContent}`),
  };
};

// PublisherAPI: GET /pages/{pageGuid}/content/versions/{version}/{copyType}/no-lock, remove clientApplication.params, add editionWindowId
export const pageContentGetPageContentWithoutLockVersion = async (pageGuid: string,
                                                                  version: number,
                                                                  clientApplication: ClientApplicationModel): Promise<IPageContentGetPageContentWithoutLockResponse> => {
  const location = '/Publisher/PageContent/GetPageContentByVersion';
  const response = await api<IPageContentGetPageContentWithoutLockResponse>(`${location}?pageGuid=${pageGuid}&copyType=draftCopy&version=${version}&${clientApplication.params}`);
  const rawResponse = await response.json();
  return {
    ...rawResponse,
    pageContent: JSON.parse(`${rawResponse.pageContent}`),
  };
};
