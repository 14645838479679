import { XMath } from '../../core/XMath';
import { Primes } from '../../core/sets/Primes';
import { WExpression } from '../../elements/tokens/WExpression';
import { Environment } from '../../expr/Environment';
import { OperationNode } from '../../funcs/expr/model/OperationNode';
import { RootNode } from '../../funcs/expr/model/RootNode';
import { ValueNode } from '../../funcs/expr/model/ValueNode';

export class RandomNumericExpressionImpl {

  /**
   * Number of time the system will retry to have the final value. This should be 0 on development or unit testing environment.
   * In production code, I recommend to put something like 10. This is a fail safe. The goal is to never have to retry. But
   * if some exception happen, we want to ssytem to retry. If the user enter something that will never produce a result, the
   * produced expression will return something different from the specified final value.
   */
  private static RETRY_UNTIL_FINAL_VALUE_MATCH:number = 99;

  // Indicate if group operator was used during break operations
  private groupFlag:boolean;

  /**
   *
   */
  private value:number;

  /**
   *
   */
  private operators:string;

  /**
   *
   */
  private nbOperations:number;

  /**
   *
   */
  private env:Environment;

  /**
   *
   */
  constructor(
      value:number,
      operators:string,
      nbOperations:number,
      env:Environment) {

    this.value = value;
    this.operators = operators;
    this.nbOperations = nbOperations;
    this.env = env;
  }

  /**
   * In this method, we start with the end value and try
   * to create an expression that will resolve to that value.
   */
  public nextExpression():WExpression{
    const n:number = this.nbOperations;

    let root:RootNode;
    let op:string;
    let r:boolean;

    let trials:number = 0;
    let operatorIndex:number;

    // Will try to regenerate until it contains parenthesis
    const parenthesis:boolean =
      this.operators.indexOf('(') !== -1 ||
      this.operators.indexOf(')') !== -1;
    let exitCondition:boolean = true;

    do{
      // Try to apply each operator at least once
      root = new RootNode(new ValueNode(this.value));
      operatorIndex = 0;
      this.groupFlag = false;

      let distinctOperators:any[] = this.parseOperators(this.operators, true);
      const excludedDistincts:any[] = [];
      if(distinctOperators.length > 0){
        do{
          op = this.take(distinctOperators);
          r = this.breakValue(root, op, this.value, operatorIndex);
          if(r){
            operatorIndex++;
            distinctOperators = distinctOperators.concat(excludedDistincts);
          }else{
            excludedDistincts.push(op);
          }
          distinctOperators.splice(distinctOperators.indexOf(op), 1); // used, we remove it
        }while(operatorIndex < n && distinctOperators.length > 0);
      }

      let allOperators:any[] = this.parseOperators(this.operators);
      const excluded:any[] = [];

      while(allOperators.length > 0 && operatorIndex < n){
        op = this.take(allOperators);
        r = this.breakValue(root, op, this.value, operatorIndex);

        if(r){
          operatorIndex++;
          allOperators = allOperators.concat(excluded);
        }else{
          excludedDistincts.push(op);
          allOperators.splice(allOperators.indexOf(op), 1);
        }
      }

      exitCondition = (root.value === this.value &&
                      operatorIndex === n &&
                      (parenthesis ? this.groupFlag : true)) ||
        trials >= RandomNumericExpressionImpl.RETRY_UNTIL_FINAL_VALUE_MATCH;

      trials++; // Failsafe
    }while(!exitCondition);
    return this.env.createExpression(root.toString());
  }

  /**
   *
   */
  private breakValue(root:RootNode, op:string, finalValue:number, opi:number):boolean{
    if(op === '+'){
      return this.breakAsAddition(root);
    }
    if(op === '-'){
      return this.breakAsSubtraction(root);
    }
    if(op === '×'){
      return this.breakAsMultiplication(root);
    }
    if(op === '÷'){
      return this.breakAsDivision(root, finalValue, opi);
    }
    if(op === '^'){
      return this.breakAsExponentiation(root);
    }
    return false;
  }

  /**
   *
   */
  private breakAsAddition(root:RootNode):boolean{
    let values:any[] = [];
    root.values(values);
    values = this.withoutPowers(values);

    let max:ValueNode;
    for(let i:number = 0 ; i < values.length ; i++){
      const value:ValueNode = values[i];
      if(!max || value.value > max.value) {
        max = value;
      }
    }

    if(max){
      const n:number = max.value;
      let t:number = n - this.env.prng.randomIndex(n); // Le 1 +  pourrait poser problème ici
      if(t === 0){
        t += 1;
      }
      if(t === n){
        t -= 1;
      }

      const op:OperationNode = new OperationNode(new ValueNode(t), '+', new ValueNode(n - t), max.parent.isMultiplicative || max.isSubstracted);

      this.groupFlag = op.group;

      max.replace(op);
    }

    return max != null;
  }

  /**
   *
   */
  private breakAsSubtraction(root:RootNode):boolean{
    let values:any[] = [];
    root.values(values);
    values = this.withoutPowers(values);

    let min:ValueNode;
    for(let i:number = 0 ; i < values.length ; i++){
      const value:ValueNode = values[i];
      if(!min || value.value < min.value){
        min = value;
      }
    }

    if(min){
      const n:number = min.value;
      const t:number = n + this.env.prng.randomIndex(n) + 1;

      const op:OperationNode = new OperationNode(new ValueNode(t), '-', new ValueNode(t - n), min.parent.isMultiplicative || min.isSubstracted);

      this.groupFlag = op.group;

      min.replace(op);
    }

    return min != null;
  }

  /**
   *
   */
  private breakAsDivision(root:RootNode, finalValue:number, opi:number):boolean{
    let values:any[] = [];
    root.values(values);
    values = this.withoutPowers(values);

    if(values.length > 0){
      const target:ValueNode = values[this.env.prng.randomIndex(values.length)];
      let maxf:number = finalValue / 2;
      for(let i:number = 0 ; i < (6 - opi) ; i++){
        maxf *= 0.5;
      }
      maxf = Math.max(2, maxf);
      const f:number = 2 + this.env.prng.randomIndex(maxf - 2);
      const op:OperationNode = new OperationNode(new ValueNode(target.value * f), '÷', new ValueNode(f), target.isBase || target.isDivisor);

      this.groupFlag = op.group;

      target.replace(op);
    }

    return values.length > 0;
  }

  /**
   *
   */
  private breakAsExponentiation(root:RootNode):boolean{
    let values:any[] = [];
    root.values(values);
    values = this.withoutPowers(values);
    values = this.withoutBases(values);

    const squares:any[] = [];
    const cubes:any[] = [];

    for(let i:number = 0 ; i < values.length ; i++){
      const value:ValueNode = values[i];
      if(value.value > 1){
        const root2:number = Math.sqrt(value.value);
        const root3:number = value.value ** (1/3);
        if(root2 === Math.floor(root2)){
          squares.push(value);
        }
        if(root3 === Math.floor(root3)){
          cubes.push(value);
        }
      }
    }

    if(squares.length > 0 || cubes.length > 0){
      let target:ValueNode;
      let power:number;

      if(squares.length > 0){
        target = squares[this.env.prng.randomIndex(squares.length)];
        power = 2;
      }else if(cubes.length > 0){
        target = cubes[this.env.prng.randomIndex(cubes.length)];
        power = 3;
      }

      const op:OperationNode = new OperationNode(new ValueNode(Math.round(target.value ** (1 / power))), '^', new ValueNode(power), false);

      this.groupFlag = op.group;

      target.replace(op);
      return true;
    }

    return false;
  }

  /**
   *
   */
  private breakAsMultiplication(root:RootNode):boolean{
    let values:any[] = [];
    root.values(values);
    values = this.withoutPowers(values);

    const primes:Primes = new Primes();
    const composedValues:any[] = [];
    for(let i:number = 0 ; i < values.length ; i++){
      const value:ValueNode = values[i];
      if(primes.notElementOf(value.value)){
        composedValues.push(value);
      }
    }

    let target:ValueNode;
    if(composedValues.length > 0){
      target = composedValues[this.env.prng.randomIndex(composedValues.length)];
    }else if(values.length > 0){
      target = values[this.env.prng.randomIndex(values.length)];
    }

    if(target){
      let divisors:any[] = XMath.divisors(target.value);

      if(divisors.length > 2){
        divisors = divisors.slice(1, divisors.length - 1);
      }

      const t:number = divisors[this.env.prng.randomIndex(divisors.length)];
      const n:number = target.value / t;
      const op:OperationNode = new OperationNode(new ValueNode(t), '×', new ValueNode(n), target.isBase);

      this.groupFlag = op.group;

      target.replace(op);
    }

    return target != null;
  }

  /**
   *
   */
  private withoutPowers(values:any[]):any[]{
    return values.filter(this.filterWithoutPower, null);
  }

  private filterWithoutPower(value:ValueNode, ..._:any[]):boolean{
    return !value.isPower;
  }

  /**
   *
   */
  private withoutBases(values:any[]):any[]{
    return values.filter(this.filterWithoutBases, null);
  }

  private filterWithoutBases(value:ValueNode, ..._:any[]):boolean{
    return !value.isBase;
  }

  /**
   *
   */
  private take(values:any[]):string{
    const index = this.env.prng.randomIndex(values.length);
    return values[index];
  }

  /**
   *
   */
  private parseOperators(value:string, distinct:boolean = false):any[]{
    let op:any[] = value.split('');

    op = op.map(this.normalizeOperator, null);
    op = op.filter(this.filterOperators, null);

    if(distinct){
      const temp:any[] = [];
      for(let i:number = 0 ; i < op.length ; i++){
        const o:string = op[i];
        if(temp.indexOf(o) === -1){
          temp.push(o);
        }
      }
      return temp;
    }

    return op;
  }

  private normalizeOperator(o:string, ..._:any[]):string{
    if(o === '*'){
      return '×';
    }
    if(o === '/'){
      return '÷';
    }
    return o;
  }

  private filterOperators(o:string, ..._:any[]):boolean{
    return String('+-÷^×').indexOf(o) !== -1;
  }

}
