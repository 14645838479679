import { publisherApi } from '../../utils/fetch';

export const mergeContentTags = async (sourceContentTagIds: ReadonlyArray<number>, targetContentTagId: number): Promise<boolean> => {
  const response = await publisherApi(`/content-tags/merge`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sourceContentTagIds,
      targetContentTagId
    }),
  });
  return response.ok;
};
