import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';

export const pageContentArchiveVersion = async (pageGuid: string,
                                                version: number,
                                                clientApplication: ClientApplicationModel): Promise<boolean> => {

  const data = new FormData();
  data.append('pageGuid', pageGuid);
  data.append('version', String(version));

  // PublisherAPI: PATCH /pages/{pageGuid}/content/versions/{version}/archive, remove clientApplication.params
  const response = await api(`/Publisher/PageContent/ArchiveVersion?${clientApplication.params}`, {
    method: 'POST',
    body: data,
  });
  return response.ok;
};
