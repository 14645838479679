import { MmlWriter } from '../../../core/mml/MmlWriter';
import { RealElement } from '../../../elements/abstract/RealElement';
import { WRational } from '../../../elements/tokens/WRational';
import { CultureInfo } from '../../../localization/CultureInfo';
import { IWriterBase } from '../../../expr/conversion/writers/IWriterBase';

/**
 *
 */
export class MathMLWriter2 implements IWriterBase {

  // underlying writer
  private base:MmlWriter;

  private culture:CultureInfo;

  constructor(base:MmlWriter, culture:CultureInfo) {

    this.base = base;
    this.culture = culture;
  }

  public get fractionsAvailable():boolean{
    return true;
  }

  public writeNumber(value:number):void{
    this.base.appendNumber(this.culture.formatNumber(value));
  }

  public writeReal(value:RealElement):void{
    if(value instanceof WRational){
      this.base.beginFraction();
      this.writeNumber((<WRational>value ).numerator);
      this.writeNumber((<WRational>value ).denominator);
      this.base.endFraction();
    }else{
      this.writeNumber(value.toNumber());
    }
  }

  public writeOperator(value:string):void{
    this.base.appendOperator(value);
  }

  public writePrefixOperator(value:string):void{
    this.base.appendOperator(value);
    this.base.form = 'prefix';
  }

  /**
   * Variables names, function names and symbolic constants.
   */
  public writeIdentifier(value:string):void{
    this.base.appendIdentifier(value);
  }

  public writeSymbol(value:string, italic:boolean):void{
    this.base.appendIdentifier(value);
    this.base.mathvariant = italic ? 'italic' : 'normal';
  }

  private simpleStructure:boolean = false;

  public beginSimpleStructure():void{this.simpleStructure = true;}

  public endSimpleStructure():void{this.simpleStructure = false;}

  public beginWriteFractionNumerator():void{
    this.base.beginFraction();
    if(!this.simpleStructure){
      this.base.beginRow();
    }
  }

  public beginWriteFractionDenominator():void{
    this.beginRow();
  }

  public endWriteFraction():void{
    if(!this.simpleStructure){
      this.base.endRow();
    }
    this.base.endFraction();
  }

  public beginWriteExponentBase():void{
    this.base.beginSup();
    if(!this.simpleStructure){
      this.base.beginRow();
    }
  }

  public beginWriteExponentScript():void{
    this.beginRow();
  }

  public endWriteExponentScript():void{
    this.endRow();
  }

  public endWriteExponent():void{
    this.base.endSup();
  }

  public beginWriteSubscriptBase():void{
    this.base.beginSub();
    if(!this.simpleStructure){
      this.base.beginRow();
    }
  }

  public beginWriteSubscriptScript():void{
    this.beginRow();
  }

  public endWriteSubscriptScript():void{
    this.endRow();
  }

  public endWriteSubscript():void{
    this.base.endSub();
  }

  private endRow():void{
    if(!this.simpleStructure){
      this.base.endRow();
    }
  }

  private beginRow():void{
    if(!this.simpleStructure){
      this.base.endRow();
      this.base.beginRow();
    }
  }

}
