import { Point } from '../../js/geom/Point';

const compareValues = <T extends number | string>(a: T, b: T): number => {
  if(a < b){
    return -1;
  }
  if(a > b){
    return 1;
  }
  return 0;
}

/**
 *
 */
export class XSort {

  /**
   *
   */
  public static numeric(
      a:number,
      b:number):number{
    return compareValues(a, b);
  }

  /**
   *
   */
  public static xycoordinate(
      a:Point,
      b:Point):number{
    const value:number = XSort.numeric(a.x, b.x);
    if(value !== 0){
      return value;
    }
    return XSort.numeric(a.y, b.y);
  }

  /**
   *
   */
  public static yxcoordinate(
      a:Point,
      b:Point):number{
    const value:number = XSort.numeric(a.y, b.y);
    if(value !== 0){
      return value;
    }
    return XSort.numeric(a.x, b.x);
  }

  /**
   *
   */
  public static xcoordinate(
      a:Point,
      b:Point):number{

    return XSort.numeric(a.x, b.x);
  }

  /**
   *
   */
  public static ycoordinate(
      a:Point,
      b:Point):number{

    return XSort.numeric(a.y, b.y);
  }

  /**
   *
   */
  public static str(a:string, b:string):number{
    return compareValues(a, b);
  }

  /**
   *
   */
  public static strLength(a:string, b:string):number{

    if(a.length > b.length){
      return -1;
    }
    if(a.length < b.length){
      return 1;
    }
    return 0;
  }

  /**
   * Sort target list based on the source values.
   */
  public static parallel(
      source:number[],
      target:number[]):number[]{

    if(!source || !target){
      return null;
    }
    if(source.length !== target.length){
      return null;
    }

    let i:number;
    let p:Point[] = [];
    for(i = 0 ; i < source.length ; i++){
      p.push(new Point(source[i], target[i]));
    }

    p = p.sort(XSort.xcoordinate);

    const o:number[] = [];

    for(i = 0 ; i < p.length ; i++){
      o.push(p[i].y);
    }

    return o;
  }

}
