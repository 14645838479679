import { allVariants } from './devServerFallbacks';
import { publisherApi } from '../utils/fetch';

export const getAllVariants = async (): Promise<ReadonlyArray<string>> => {
  const response = await publisherApi<ReadonlyArray<string>>('/variants');
  return response.json();
};

export const getLocalVariants = (): ReadonlyArray<string> => {
  return allVariants;
}
