import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';

export interface IPageContentRestoreVersionResponse {
  status: boolean;
  headVersion: number;
}

// PublisherAPI: PATCH /pages/{pageGuid}/content/versions/{version}/restore, remove clientApplication.params
export const pageContentRestoreVersion = async (pageGuid: string,
                                                version: number,
                                                clientApplication: ClientApplicationModel): Promise<IPageContentRestoreVersionResponse> => {

  const data = new FormData();
  data.append('pageGuid', pageGuid);
  data.append('version', String(version));

  const response = await api<IPageContentRestoreVersionResponse>(`/Publisher/PageContent/RestoreVersion?${clientApplication.params}`, {
    method: 'POST',
    body: data,
  });
  return response.json();
};
