import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';

// PublisherAPI: POST /pages/{pageGuid}/content/release-lock, remove clientApplication.params, add editionWindowId
export const pageContentReleaseLock = async (pageGuid: string,
                                             clientApplication: ClientApplicationModel): Promise<boolean> => {

  const response = await api(`/Publisher/PageContent/ReleaseLock?pageGuid=${pageGuid}&${clientApplication.params}`, {
    keepalive: true,
  });
  return response.ok;
};
