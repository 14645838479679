import { Match } from '../../../core/Match';
import { XMath } from '../../../core/XMath';
import { XNumber } from '../../../core/XNumber';
import { XRound } from '../../../core/XRound';
import { ScriptFormat } from '../../../core/str/ScriptFormat';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';
import { NumberWordsFormatter } from '../../../elements/formats/numbers/NumberWordsFormatter';
import { XString } from '../../../core/XString';

/**
 * This is the basic number formatting option,
 * format the number accoring to the locale format.
 */
export class LocaleNumberFormatter extends BaseNumberFormatter {

  /**
   *
   */
  private _minDecPlaces:number;

  public get minDecPlaces():number{return this._minDecPlaces;}

  /**
   *
   */
  private _maxDecPlaces:number;

  public get maxDecPlaces():number{return this._maxDecPlaces;}

  /**
   * Indicates whether minDecPlaces is applied on integers.
   * Currency precision works like if keepIntegers was true.
   */
  private _keepIntegers:boolean;

  public get keepIntegers():boolean{return this._keepIntegers;}

  private _thousandSeparatorThreshold: number;

  public get thousandSeparatorThreshold(): number { return this._thousandSeparatorThreshold; }

  constructor(
      culture:CultureInfo,
      minDecPlaces:number = 0,
      maxDecPlaces:number = Number.MAX_SAFE_INTEGER,
      keepIntegers:boolean = false,
      thousandSeparatorThreshold: number = Number.NaN){
    super(culture);
    this._minDecPlaces = minDecPlaces;
    this._maxDecPlaces = maxDecPlaces;
    this._keepIntegers = keepIntegers;
    this._thousandSeparatorThreshold = thousandSeparatorThreshold;
  }

  public precisionLoss(value:number):boolean{
    return this.formatValue(value) !== value;
  }

  public formatValue(value:number):number{
    if(this.maxDecPlaces === Number.MAX_SAFE_INTEGER){
      return value;
    }
    return XRound.halfAway(value, this.maxDecPlaces);
  }

  public toLocaleString(valueArg:number):string{
    let value = valueArg;
    if (	value === Number.POSITIVE_INFINITY ||
        value === Number.NEGATIVE_INFINITY) {
      return value.toString();
    }

    if(this.maxDecPlaces !== Number.MAX_SAFE_INTEGER && XNumber.decimals(value) > 0) {
      value = XRound.halfAway(value, this.maxDecPlaces);
    }

    const s:string = XNumber.toDecimalString(value);
    if(s.toLowerCase().indexOf('e') !== -1){
      return this.formatExponential(s);
    }

    let thousandSeparator:string = this.thousandSeparator;
    const thousandSeparatorThreshold =
      isNaN(this.thousandSeparatorThreshold)
        ? this.culture.configuration.thousandSeparatorThreshold
        : this.thousandSeparatorThreshold;

    if(Math.abs(value) < thousandSeparatorThreshold){
      thousandSeparator = '';
    }

    const o:any[] = XNumber.toDecimalString(Math.abs(value)).split('.');

    if(this.minDecPlaces > 0){
      if(!(this.keepIntegers && XMath.isInteger(value))){
        if(o.length === 1){
          o.push('');
        }
        while(o[1].length < this.minDecPlaces){
          o[1] += '0';
        }
      }
    }

    let n:string =
      o.length > 1 ?
        this.formatIntegerPart(o[0], thousandSeparator) + this.decimalSeparator + this.formatDecimalPart(o[1], this.thousandthSeparator) :
        this.formatIntegerPart(o[0], thousandSeparator);

    if(n.charAt(0) === this.decimalSeparator && this.leadingZero){
      n = `0${n}`;
    }

    if(value < 0){
      n = this.negativeSign + n;
    }

    return n;
  }

  /**
   *
   */
  private formatExponential(value:string):string{
    const rx:RegExp = /^([+-])?(\d+).?(\d*)[eE]([-+]?\d+)$/;
    const o:Match = Match.tryParse(rx.exec(value));
    const sign:string = o.groups[1];
    const first:string = o.groups[2];
    let last:string =  o.groups[3];
    if (last.length > this._maxDecPlaces) {
      last = last.slice(0, this._maxDecPlaces);
    }

    const exp:number = Number(o.groups[4]);
    const signPart = sign || '';
    const firstPart = first || '0';
    const lastPart = last ? `.${last}` : '';
    const exponentPart = `\u00A0×\u00A010${ScriptFormat.superscript(exp)}`;
    return `${signPart}${firstPart}${lastPart}${exponentPart}`;
  }

  /**
   * ? --> thousandSeparator
   * 1234567 --> 1?234?567
   */
  private formatIntegerPart(integerArg:string, thousandSeparator:string):string{
    let integer = integerArg;
    if(!thousandSeparator){
      return integer;
    }

    const o:any[] = [];
    while(integer.length > 0){
      o.unshift(integer.substring(Math.max(0, integer.length - 3)));
      integer = integer.substring(0, integer.length - 3);
    }

    return o.join(thousandSeparator);
  }

  /**
   * ? --> thousandSeparator
   * 1234567 --> 123?456?7
   */
  private formatDecimalPart(decimalArg:string, thousandthSeparator:string):string{
    let decimal = decimalArg;
    if(!thousandthSeparator){
      return decimal;
    }

    const o:any[] = [];
    while(decimal.length > 0){
      const k:number = Math.min(3, decimal.length);
      o.push(decimal.substring(0, k));
      decimal = decimal.substring(k);
    }

    return o.join(thousandthSeparator);
  }

  public toSpeakText(value:number):string{
    return (new NumberWordsFormatter(this.culture)).toLocaleString(value);
  }

  // ***************************************************
  public get currencySymbolPlacement():string{
    return this.culture.getString('LocaleNumbers.currencySymbolPlacement');
  }

  public get currencyFormat():string{
    return this.culture.getString('LocaleNumbers.currencyFormat');
  }

  public get negativeCurrencyFormat():string{
    return this.culture.getString('LocaleNumbers.negativeCurrencyFormat');
  }

  public get centsFormat():string{
    return this.culture.getString('LocaleNumbers.centsFormat');
  }

  public get dollarsLabel():string{
    return this.culture.getString('LocaleNumbers.dollarsLabel');
  }

  public get centsLabel():string{
    return this.culture.getString('LocaleNumbers.centsLabel');
  }

  public get negativeLabel():string{
    return this.culture.getString('LocaleNumbers.negativeLabel');
  }

  public get andLabel():string{
    return this.culture.getString('LocaleNumbers.andLabel');
  }

  public getDecimalSeparatorLabel(plural: boolean, prefixWords: ReadonlyArray<string> = null): string {
    let label = null;
    switch(this.culture.configuration.decimalSeparator){
      case '.':
        label = this.culture.getString(plural ? 'LocaleNumbers.decimalPointsLabel' : 'LocaleNumbers.decimalPointLabel');
        break;
      case ',':
        label = this.culture.getString(plural ? 'LocaleNumbers.decimalCommasLabel' : 'LocaleNumbers.decimalCommaLabel');
        break;
      default:
        throw new Error();
    }
    return XString.acceptWords(label, prefixWords);
  }

  public get decimalSeparator():string{
    return this.culture.configuration.decimalSeparator;
  }

  public get thousandSeparator():string{
    return this.culture.configuration.thousandSeparator;
  }

  public get thousandthSeparator():string{
    return this.culture.configuration.thousandthSeparator;
  }

  public get leadingZero():boolean{
    return true;
  }

  public get negativeSign():string{
    return '−';
  }

  public get percentFormat():string{
    return this.culture.configuration.spacedPercentFormat ?
      '{0} %' : '{0}%';
  }

}
