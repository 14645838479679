import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';

export interface IPageContentRenewLockResponse {
  pageGuid: string;
  status: 'acquired' | 'failed';
  lockedBy: string;
}

// PublisherAPI: POST /pages/{pageGuid}/content/renew-lock, remove clientApplication.params, add editionWindowId
export const pageContentRenewLock = async (pageGuid: string,
                                           clientApplication: ClientApplicationModel): Promise<IPageContentRenewLockResponse> => {
  const response = await api<IPageContentRenewLockResponse>(`/Publisher/PageContent/RenewLock?pageGuid=${pageGuid}&${clientApplication.params}`);
  return response.json();
};
