import { Point } from '../../../../js/geom/Point';

import { XGeom } from '../../../core/XGeom';
import { XSort } from '../../../core/XSort';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { TokenElement } from '../../../elements/abstract/TokenElement';
import { WListOfPoints } from '../../../elements/tokens/WListOfPoints';
import { WListOfSegments } from '../../../elements/tokens/WListOfSegments';
import { WPoint } from '../../../elements/tokens/WPoint';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { WSegment } from '../../../elements/tokens/WSegment';
import { WSpecialCase } from '../../../elements/tokens/WSpecialCase';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { Environment } from '../../../expr/Environment';
import { XRound } from '../../../core/XRound';

/**
 *
 */
export class Intersection extends FunctionElement {

  private static NO_INTERSECTION:WSpecialCase =
    new WSpecialCase('No intersection');

  private static POINTS_SEGMENTS_SET:WSpecialCase =
    new WSpecialCase('Points and segments mixed');

  /**
   *
   */
  public callReturnElement(args:ArgumentsObject):ContentElement{
    if(args.length !== 2){
      return args.expectingArguments(2, 2);
    }

    if (args.getPoint(0) && args.getPoint(1)) {
      return this.pointPoint(args.getPoint(0), args.getPoint(1));
    }

    if (args.getSegment(0) && args.getSegment(1)) {
      return this.segmentSegment(args.getSegment(0), args.getSegment(1), args.env);
    }

    if (args.getSegment(0) && args.getPoint(1)) {
      return this.segmentPoint(args.getSegment(0), args.getPoint(1));
    }

    if (args.getPoint(0) && args.getSegment(1)) {
      return this.pointSegment(args.getPoint(0), args.getSegment(1));
    }

    if (args.getPolygon(0) && args.getPoint(1)) {
      return this.polygonPoint(args.getPolygon(0), args.getPoint(1));
    }

    if (args.getPoint(0) && args.getPolygon(1)) {
      return this.pointPolygon(args.getPoint(0), args.getPolygon(1));
    }

    if (args.getPoints(0) && args.getPoints(1)) {
      return this.pointsPoints(args.getPoints(0), args.getPoints(1), args.env);
    }

    if (args.getSegments(0) && args.getSegments(1)) {
      return this.segmentsSegments(args.getSegments(0), args.getSegments(1), args.env);
    }

    return null;
  }

  /**
   *
   */
  private pointPoint(
      a:WPoint,
      b:WPoint):TokenElement{
    if(a.equalsTo(b)){
      return a;
    }
    return Intersection.NO_INTERSECTION;
  }

  /**
   *
   */
  private segmentSegment(
      a:WSegment,
      b:WSegment,
      env:Environment):TokenElement{
    const i:TokenElement = this.segmentsImpl(a.a, a.b, b.a, b.b, env);
    return i || Intersection.NO_INTERSECTION;
  }

  /**
   *
   */
  private segmentPoint(
      s:WSegment,
      p:WPoint):TokenElement{
    // point|no_intersection
    if(s.containsPoint(p.toPoint())){
      return p;
    }
    return Intersection.NO_INTERSECTION;
  }

  /**
   *
   */
  private pointSegment(
      p:WPoint,
      s:WSegment):TokenElement{
    return this.segmentPoint(s, p);
  }

  /**
   *
   */
  private polygonPoint(
      poly:WPolygon,
      pt:WPoint):TokenElement{
    // point|no_intersection
    if(poly.containsPoint(pt.toPoint())){
      return pt;
    }
    return Intersection.NO_INTERSECTION;
  }

  /**
   *
   */
  private pointPolygon(
      pt:WPoint,
      poly:WPolygon):TokenElement{
    return this.polygonPoint(poly, pt);
  }

  /**
   *
   */
  private pointsPoints(
      a:WListOfPoints,
      b:WListOfPoints,
      env:Environment):TokenElement{

    const o:ContentElement[] = [];

    for(let i:number = 0 ; i < a.count ; i++){
      const pa:WPoint = a.getTypedItemAt(i);
      for(let j:number = 0 ; j < b.count ; j++){
        const pb:WPoint = b.getTypedItemAt(j);
        if(pa.equalsTo(pb)){
          o.push(pa);
        }
      }
    }

    return o.length === 0 ?
        Intersection.NO_INTERSECTION :
        new WListOfPoints(o, env.culture.listFormatter);
  }

  /**
   *
   */
  private segmentsSegments(
      a:WListOfSegments,
      b:WListOfSegments,
      env:Environment):TokenElement{

    const points:ContentElement[] = [];
    const segments:ContentElement[] = [];

    // Find all intersections
    for(let i:number = 0 ; i < a.count ; i++){
      const s1:WSegment = a.getTypedItemAt(i);
      for(let j:number = 0 ; j < b.count ; j++){
        const s2:WSegment = b.getTypedItemAt(j);
        const intersection:TokenElement = this.segmentsImpl(s1.a, s1.b, s2.a, s2.b, env);
        if(intersection instanceof WPoint){
          points.push(<WPoint>intersection );
        }
        if(intersection instanceof WSegment){
          segments.push(<WSegment>intersection );
        }
      }
    }

    // All points that lies on intersection segments can be removed because they are already part of the intersection.
    let k:number = 0;
    while(k < points.length){
      let preserve:boolean = true;
      for(let l:number = 0 ; l < segments.length ; l++){
        if(XGeom.pointOnSegment(
            (<WPoint>points[k] ).toPoint(),
            (<WSegment>segments[l] ).a,
            (<WSegment>segments[l] ).b)){
          preserve = false;
          break;
        }
      }
      if(preserve){
        k++;
      }else{
        points.splice(k, 1);
      }
    }

    if(points.length > 0 && segments.length > 0){
      return Intersection.POINTS_SEGMENTS_SET;
    }
    if(points.length > 0){
      return new WListOfPoints(points, env.culture.listFormatter);
    }
    if(segments.length > 0){
      return new WListOfSegments(segments, env.culture.listFormatter);
    }

    return Intersection.NO_INTERSECTION;
  }

  /**
   *
   */
  private segmentsImpl(
      s1a:Point,
      s1b:Point,
      s2a:Point,
      s2b:Point,
      env:Environment):TokenElement{

    if(XGeom.segmentsOverlap(s1a, s1b, s2a, s2b)){
      const p:Point[] = [];
      p.push(s1a, s1b, s2a, s2b);
      p.sort(XSort.xycoordinate);
      const a:Point = p[1];
      const b:Point = p[2];
      if(a.equals(b)){
        return env.culture.parsePoint(XRound.safeRoundPoint2(a));
      }
      return new WSegment(a, b);
    }
    const i:Point = XGeom.segmentsIntersection(s1a, s1b, s2a, s2b);
    if(i){
      return env.culture.parsePoint(XRound.safeRoundPoint2(i));
    }
    return null;
  }

}
