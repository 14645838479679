import { ClientApplicationModel } from '../../models/ClientApplicationModel';
import { api } from '../../utils/fetch';

// PublisherAPI: PATCH /pages/{pageGuid}/content/versions/{version}/invalidate, remove clientApplication.params
export const pageContentInvalidateVersion = async (pageGuid: string,
                                                   version: number,
                                                   clientApplication: ClientApplicationModel): Promise<boolean> => {

  const data = new FormData();
  data.append('pageGuid', pageGuid);
  data.append('version', String(version));

  const response = await api(`/Publisher/PageContent/InvalidateVersion?${clientApplication.params}`, {
    method: 'POST',
    body: data,
  });
  return response.ok;
};
