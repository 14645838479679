import { IContentTag } from '../../models/IContentTag';
import { publisherApi } from '../../utils/fetch';

export const updateContentTag = async (contentTag: IContentTag) => {
  const data = {
    subjectId: contentTag.subjectId,
    name: contentTag.name,
    synonyms: {
      fr: '',
    },
    notes: contentTag.description,
  };

  const response = await publisherApi(`/content-tags/${contentTag.id}`, {
    method: 'patch',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  });
  return response.ok;
};
