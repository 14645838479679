import { publisherApi } from '../../utils/fetch';

export const removePageContentTags = async (pageGuid: string, contentTagIds: ReadonlyArray<number>) => {
  const data = contentTagIds.map((contentTagId) => ({
    pageGuid,
    contentTagId,
  }));

  const response = await publisherApi('/content-tags/untag-pages', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ pagesTags: data }),
  });
  return response.ok;
};
